import { ROUTE_LOGIN, ROUTE_REGISTER } from 'modules/Auth/routes';
import {
  landingPageExecutorsContent,
  landingPageClauseContent,
  landingPageProceedingsContent,
  landingPageAgreementsContent
} from 'modules/Layout/component/Card/LandingPage/ladingPageContent';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import Accordion from 'modules/Layout/component/Accordion';

const PublicMainView = () => {
  useEffect(() => {}, []);

  const displayHeaderElement = (
    <div className="ml-auto d-flex align-items-center" style={{ paddingRight: '65px' }}>
      <Link to="/login" className="nav-link d-flex align-items-center btn btn-link header-link">
        Zaloguj się
      </Link>
    </div>
  );

  const headerStyle = { padding: '1rem 2.2rem 1rem 1.5rem' };

  return (
    <PublicWrapper displayHeaderElement={displayHeaderElement}>
      <div className="landing-page-view">
        <div className="landing-page-content card pzpeu-card">
          <div className="title font-poppins">Witaj w Asystencie Postępowania</div>
          <div className="sub-title font-poppins">Poznaj nową funkcjonalność Asystenta Postępowania:</div>
          <div className="landing-page-accordion">
            <Accordion entity={landingPageExecutorsContent} headerStyle={headerStyle} />
            <Accordion entity={landingPageClauseContent} headerStyle={headerStyle} />
            <Accordion entity={landingPageProceedingsContent} headerStyle={headerStyle} />
            <Accordion entity={landingPageAgreementsContent} headerStyle={headerStyle} />
          </div>
          <div className="d-flex justify-content-between links flex-xl-nowrap flex-wrap" style={{ marginTop: '88px' }}>
            <Link
              to={ROUTE_LOGIN}
              className="btn btn-primary waves-effect waves-light mr-xl-1 ml-xl-0 mr-auto ml-auto mt-2"
            >
              Zaloguj się
            </Link>
            <Link
              to={ROUTE_REGISTER}
              className="btn btn-dark waves-effect waves-light ml-xl-1 mr-xl-0 mr-auto ml-auto mt-2"
            >
              Załóż darmowe konto
            </Link>
          </div>
        </div>
      </div>
    </PublicWrapper>
  );
};

export default PublicMainView;
